.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100vh;
    width:100vw;
    margin-top:0px;    
    overflow-y: scroll;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    /* background-color: #f5f6f6; */
    background-color: rgba(0,0,0,.5);
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, .4);
    margin-top:250px;
  }
  
  .login-form label {
    margin-top: 10px;
    font-size: 18px;
  }

  .login-form h3{
    color:white
  }

  .login-form input[type='text'],
  .login-form input[type='password'] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    border: none;
    border-bottom: 2px solid #ccc;
  }
  
  .login-form button[type='submit'] {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    margin-top: 20px;
    background-color:#FF4045;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
